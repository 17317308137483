import { graphql } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import Helmet from 'react-helmet';
import Apply from '../components/Apply';
import Layout from '../components/Layout';
import Title from '../components/Title';

function splitByH2(html = '') {
  const arr = html?.split('\n');
  const output = [];
  let group = [];
  arr?.forEach(item => {
    if (item.startsWith('<h2')) {
      if (group.length) {
        output.push(group);
      }
      group = [item];
    } else {
      group.push(item);
    }
  });
  if (group.length) {
    output.push(group);
  }
  return output;
}

const Career = ({ data, location }) => {
  const { title } = data.markdownRemark.frontmatter;
  const { html, htmlAst } = data.markdownRemark;
  const spanRef = useRef();
  const [visible, setVisible] = useState(true);
  useEffect(() => {
    if (spanRef.current) {
      const observer = new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting) {
            setVisible(false);
          } else {
            setVisible(true);
          }
        },
        { threshold: 0.2 }
      );
      observer.observe(spanRef.current);
    }
  }, []);

  return (
    <Layout bodyClass="page-career">
      <Helmet>
        <script src="https://code.jquery.com/jquery-3.5.1.slim.min.js"></script>
      </Helmet>
      <Title>
        <h1>{title}</h1>
      </Title>
      <div className="container pb-6 pb-md-10">
        <div className="row justify-content-start">
          <div className="col-12">
            <div className="service service-single">
              <div className="content">
                {splitByH2(html)?.map((ele, i) => {
                  const t = ele[0];
                  return (
                    <div key={i} className="group">
                      <div dangerouslySetInnerHTML={{ __html: t }} />
                      <div dangerouslySetInnerHTML={{ __html: ele.slice(1).join('') }} />
                    </div>
                  );
                })}
              </div>
              {/* <div className="content" dangerouslySetInnerHTML={{ __html: html }} /> */}
            </div>
            <div className="title"></div>
          </div>
        </div>
        <div ref={spanRef}>
          <Apply data={data} location={location}></Apply>
        </div>
      </div>
      {visible ? (
        <div className="apply-btn">
          <span
            onClick={() => {
              document.querySelector('#app').scrollIntoView(true);
              document.querySelector('#first_name').focus();
            }}
            className="button no-upper"
          >
            Apply for this Job
          </span>
        </div>
      ) : (
        ''
      )}
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
      }
      fields {
        slug
      }
      html
      htmlAst
    }
  }
`;

export default Career;
