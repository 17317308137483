/* eslint-disable no-undef */
import { graphql, navigate, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import LinkedInBtn from './LinkinBtn';
import Title from './Title';

const clientUrl = '/api';
const url = {
  info: clientUrl + '/linkedin/user_profile',
  email: clientUrl + '/send_email'
};

const Apply = props => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [linkUrl, setLinkUrl] = useState('');
  const data = useStaticQuery(graphql`
    query ApplyJobQuery {
      allLinkinConfigJson {
        edges {
          node {
            linkin_app_key
            linkin_uauth_url
            response_type
            scope
          }
        }
      }
      configJson {
        url {
          career_url
        }
      }
    }
  `);
  const config = data.allLinkinConfigJson.edges[0].node;

  useEffect(() => {
    setLinkUrl(
      `${config.linkin_uauth_url}?response_type=${config.response_type}&scope=${config.scope}&redirect_uri=${props.location.origin}${props.location.pathname}&client_id=${config.linkin_app_key}&state=foobar`
    );
  }, []);

  const jobTitle = props.data.markdownRemark.frontmatter.title;
  function onAttachChange(e) {
    document.getElementById('attachFile').innerText = e.target.files[0].name;
  }
  useEffect(() => {
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    const forms = document.getElementsByClassName('needs-validation');
    // Loop over them and prevent submission
    Array.prototype.filter.call(forms, function (form) {
      form.addEventListener(
        'submit',
        function (event) {
          event.preventDefault();
          event.stopPropagation();
          if (form.checkValidity() === false) {
            form.classList.add('was-validated');
            $('#first_name')?.[0]?.scrollIntoView({ behavior: 'smooth', block: 'start' });
            return;
          }
          setError('');
          setLoading(true);
          const formData = new FormData();
          const submitBtn = $('button[type=submit]');
          if (submitBtn.text() === 'SUBMITTING') {
            event.preventDefault();
            event.stopPropagation();
            return;
          }
          submitBtn.addClass('disabled');
          const checkboxFormData = {};
          // 遍历表单元素
          for (var i = 0; i < form.elements.length; i++) {
            var element = form.elements[i];
            // 忽略不带有名称的元素
            if (element.id) {
              if (element.type === 'file') {
                formData.append(element.id, element.files[0]);
              } else if (element.type === 'checkbox') {
                if (element.checked) formData.append(element.id, element.value);
              } else {
                formData.append(element.id, element.value);
              }
            } else if (element.name) {
              if (!checkboxFormData[element.name]) checkboxFormData[element.name] = [];
              if (element.checked) {
                checkboxFormData[element.name].push(element.value);
              }
            }
          }
          submitBtn.text('SUBMITTING');
          Object.keys(checkboxFormData).forEach(key => {
            formData.append(key, checkboxFormData[key]);
          });

          fetch(url.email, {
            method: 'POST',
            body: formData //自动修改请求头,formdata的默认请求头的格式是 multipart/form-data
          })
            .then(res => {
              if (res.status !== 200) throw new Error('500');
              navigate(data.configJson.url.career_url);
            })
            .catch(error => {
              submitBtn.removeClass('disabled');
              submitBtn.text('SUBMIT APPLICATION');
              setError(error.message);
              setLoading(false);
            });
        },
        false
      );
    });
  }, []);
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    const state = params.get('state');
    if (state !== 'foobar' || !code) return;
    fetch(url.info + '?code=' + code + '&redirect_uri=' + props.location.protocol + '//' + props.location.host + props.location.pathname)
      .then(response => response.json())
      .then(data => {
        if (data && data.data) {
          document.getElementById('first_name').value = data.data.given_name;
          document.getElementById('email').value = data.data.email;
          document.getElementById('last_name').value = data.data.family_name;
          document.querySelector('#app').scrollIntoView(true);
        }
        history.replaceState(null, '', props.location.pathname);
      })
      .catch(error => {
        setError(error.message);
      });
  }, []);
  return (
    <div id="app" className="col-12">
      <Title>
        <h1>Apply for this Job</h1>
      </Title>
      <div className="mb-2" style={{ display: 'flex', justifyContent: 'center' }}>
        <LinkedInBtn text="Apply With LinkedIn" href={linkUrl}></LinkedInBtn>
      </div>

      <form className="needs-validation form" id="applyForm" noValidate>
        <div style={{ visibility: 'hidden' }}>
          <input type="text" value={jobTitle} id="Applied_Job" placeholder="Applied Job" />
        </div>
        <div className="row">
          <div className="col-md-3 col-12 mb-2">
            <label htmlFor="first_name" className="required">
              First name
            </label>
            <input type="text" className="form-control" id="first_name" placeholder="First name" required />
            <div className="invalid-feedback">This field is required.</div>
          </div>
          <div className="col-md-3 col-12 mb-2">
            <label className="required" htmlFor="last_name">
              Last name
            </label>
            <input type="text" className="form-control" id="last_name" placeholder="Last name" required />
            <div className="invalid-feedback">This field is required.</div>
          </div>
          <div className="col-md-3 col-12 mb-2">
            <label className="required" htmlFor="email">
              Email
            </label>
            <div className="input-group">
              <input type="email" className="form-control" id="email" placeholder="Email" aria-describedby="inputGroupPrepend" required />
              <div className="invalid-feedback">Please use the format "text@example.com"</div>
            </div>
          </div>
          <div className="col-md-3 col-12 mb-2">
            <label htmlFor="phone">Phone</label>
            <input type="phone" className="form-control" id="phone" placeholder="Phone" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-12 mb-2">
            <label htmlFor="about_anuttacon">How did you hear about Anuttacon?</label>
            <input type="text" className="form-control" id="about_anuttacon" placeholder="" />
          </div>
          <div className="col-md-6 col-12 mb-2">
            <label htmlFor="current_company">Current company</label>
            <input type="text" className="form-control" id="current_company" placeholder="" />
          </div>
        </div>

        <div className="form-group form-check" style={{ paddingLeft: 0 }}>
          <label className="required" htmlFor="attach">
            Resume/CV
          </label>
          <div className="custom-file">
            <input type="file" className="custom-file-input form-control" id="attach" accept="image/*,.pdf,.doc,.docx,.txt,.rtf" required onChange={onAttachChange} />
            <label className="custom-file-label">
              <label htmlFor="attach">File types: pdf, doc, docx, txt, rtf</label>
              <span className="custom-file-btn">Browse</span>
            </label>
            <div className="invalid-feedback">This field is required.</div>
          </div>
          <p id="attachFile"></p>
        </div>

        <div className="mb-lg"></div>
        <h2>LINKS</h2>
        <div className="row">
          <div className="col-12 mb-2">
            <label htmlFor="profile_url">Where can we learn more about you? (LinkedIn, portfolio) URL</label>
            <input type="text" className="form-control" id="profile_url" />
          </div>
          <div className="col-12 mb-2">
            <label htmlFor="code_url">Where can we find samples of your code? URL</label>
            <input type="text" className="form-control" id="code_url" />
          </div>
          <div className="col-12 mb-2">
            <label htmlFor="research_url">Where can we find your publications, papers, or research? URL</label>
            <input type="text" className="form-control" id="research_url" />
          </div>
        </div>

        <div className="mb-lg"></div>
        <h2>U.S. Standard Demographic Questions</h2>
        <div className="row">
          <div className="col-12 mb-2">
            <label htmlFor="musicCheckbox">How would you describe your gender identity? (mark all that apply)</label>
            <div className="form-control" style={{ border: 'none', paddingLeft: 0 }}>
              <fieldset className="checkbox-container">
                {['Man', 'Woman', 'I Prefer to self-describe', `I don't wish to answer`].map(ele => (
                  <div key={ele} className="form-check form-check-inline">
                    <label className="form-check-label black-font">
                      <input className="form-check-input" name="gender" type="checkbox" value={ele} />
                      {ele}
                    </label>
                  </div>
                ))}
              </fieldset>
            </div>
          </div>

          <div className="col-12 mb-2">
            <label htmlFor="veteran">Are you a veteran or active member of the United States Armed Forces?</label>
            <select className="custom-select form-control" id="veteran">
              <option value="">-</option>
              <option value="Yes, I am a veteran or active member">Yes, I am a veteran or active member</option>
              <option value="No, I am not a veteran or active member">No, I am not a veteran or active member</option>
              <option value="I prefer to self-describe">I prefer to self-describe</option>
              <option value="I don't wish to answer">I don't wish to answer</option>
            </select>
          </div>
        </div>
        {error && (
          <div className="row mt-3 justify-content-center">
            <div className="text-center text-danger">{error}</div>
          </div>
        )}
        <div className="row mt-3 justify-content-center">
          <button className="button no-border col-3 circle" style={{ width: 'fit-content' }} type="submit">
            SUBMIT APPLICATION
          </button>
        </div>
      </form>
    </div>
  );
};

export default Apply;
