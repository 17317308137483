import React from 'react';
import Icon from '../../static/images/icons/linkin.svg';

const LinkedInBtn = props => {
  const { text, href } = props;
  return (
    <a className="linked-btn" href={href}>
      <span className="icon-container">
        <Icon className="icon"></Icon>
      </span>
      <span className="text">{text}</span>
    </a>
  );
};

export default LinkedInBtn;
